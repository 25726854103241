<template>
<div>
  <Row>
    <Col span="24">
      <Form :model="detail" :label-width="100" ref="activeForm">
        <FormItem label="名称">
          <Input v-model="detail.activity_name" style="width: 600px"></Input>
        </FormItem>
        <FormItem label="分类">
          <Select v-model="detail.activity_type" style="width:200px" p>
            <Option v-for="item in activity_type" :value="item.id" :key="item.id">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="人数要求">
          <Input v-model="detail.activity_max_people" style="width: 200px"></Input>
        </FormItem>
        <FormItem label="已报名人数">
          <Input v-model="detail.activity_apply_people" style="width: 200px" disabled></Input>
        </FormItem>
        <FormItem label="是否招募">
          <Checkbox v-model="detail.activity_need_zhaomu" :true-value="1" :false-value="0" @on-change="onZhaomuChange($event)"></Checkbox>
        </FormItem>
        <template v-if="detail.activity_need_zhaomu === 1">
          <FormItem label="资金数">
            <Input v-model="detail.funding" style="width: 200px" @on-change="onFundingChange"></Input>
          </FormItem>
          <FormItem label="已募集资金数">
            <Input v-model="detail.funding_have" style="width: 200px" disabled></Input>
          </FormItem>
          <FormItem label="管理员数">
            <Input v-model="detail.activity_manager" style="width: 200px"></Input>
          </FormItem>
          <FormItem label="已招募管理员数">
            <Input v-model="detail.activity_manager_have" style="width: 200px" disabled></Input>
          </FormItem>
        </template>
        <FormItem label="地址">
          <Input v-model="detail.activity_address" style="width: 600px" icon="ios-navigate" @on-click="callMapModal()"  disabled></Input>
        </FormItem>
        <FormItem label="发起人">
          <Input v-model="detail.user_nick" style="width: 200px" disabled></Input>
        </FormItem>
        <FormItem label="发起人姓名">
          <Input v-model="detail.activity_initiator_name" style="width: 200px"></Input>
        </FormItem>
        <FormItem label="发起人电话">
          <Input v-model="detail.activity_phone" style="width: 200px"></Input>
        </FormItem>
        <FormItem label="活动议程">
          <Input type="textarea" :rows="4" v-model="detail.activity_detail" style="width: 600px" size="large"></Input>
        </FormItem>
        <FormItem label="活动介绍">
          <Input type="textarea" :rows="4" v-model="detail.activity_purpose" style="width: 600px" size="large"></Input>
        </FormItem>
        <FormItem label="须知及注意事项">
          <Input type="textarea" :rows="4" v-model="detail.activity_notice" style="width: 600px" size="large"></Input>
        </FormItem>
        <FormItem label="问题1" v-if="detail.activity_question1">
          <Input v-model="detail.activity_question1" style="width: 600px" size="large"></Input>
        </FormItem>
        <FormItem label="问题2" v-if="detail.activity_question2">
          <Input v-model="detail.activity_question2" style="width: 600px" size="large"></Input>
        </FormItem>
        <FormItem label="问题3" v-if="detail.activity_question3">
          <Input v-model="detail.activity_question3" style="width: 600px" size="large"></Input>
        </FormItem>
        <FormItem label="图片">
          <div class="demo-upload-list">
            <img :src="detail.activity_pic52" @click="handleView(detail.activity_pic52)">
          </div>

          <div class="demo-upload-list">
            <img :src="detail.activity_pic43" @click="handleView(detail.activity_pic43)">
          </div>
        </FormItem>

<!--        <FormItem>-->
<!--          <Button size="large" type="primary" @click="submit()">确定</Button>-->
<!--        </FormItem>-->
      </Form>
    </Col>
  </Row>

  <Modal v-model="mapModal" fullscreen title="选择位置" :closable="false">
    <div style="height: 100%">
      <iframe
          id="mapPage"
          width="100%"
          height="100%"
          frameborder=0
          :src="'https://apis.map.qq.com/tools/poimarker?type=0&marker=coord:' + this.detail.activity_lat + ',' + this.detail.activity_lng + '&key=IOVBZ-K3FWP-QQKDM-L4LTV-5QP7K-DYBFF&referer=myapp'"
      >
      </iframe>
    </div>
  </Modal>

  <Modal title="View Image" v-model="visible">
    <img :src="imgName" v-if="visible" style="width: 100%">
  </Modal>
</div>
</template>

<script>
import api from "../../api/xiaochengxu";
export default {
  name: "ActivityDetail",
  data: function(){
    return {
      visible: false,
      mapModal: false,
      detail: {},
      activity_id: '',
      activity_type: [
        {
          id: 1,
          name: "享玩"
        },
        {
          id: 2,
          name: "公益"
        },
        {
          id: 3,
          name: "晓学"
        }
      ]
    }
  },
  methods: {
    handleView (name) {
      this.imgName = name;
      this.visible = true;
    },
    onZhaomuChange: function (e){
      this.detail.activity_need_zhaomu = e;
      console.log(this.detail.activity_need_zhaomu)
    },
    onFundingChange: function(e){
      this.detail.activity_funding = e.target.value * 100
    },
    callMapModal: function(){
      this.mapModal = true
    },
  },
  mounted() {
    this.$emit('lastPage', '/xiaochengxu/activity')
    this.activity_id = this.$route.query.id;
    this.$http.get(api.activityDetail, {params: {
      activity_id: this.activity_id
    }}).then(response => {
      if(response.data.success) {
        this.detail = response.data.res;
        this.detail.funding = this.detail.activity_funding / 100
        this.detail.funding_have = this.detail.activity_funding_have / 100
      } else {
        this.$Message.error(response.data.err);
        console.log(response)
      }
    })
  },
  destroyed() {
    this.$emit('lastPage', '')
  }
}
</script>

<style scoped>
.demo-upload-list{
  display: inline-block;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0,0,0,.2);
  margin-right: 4px;
}
.demo-upload-list img{
  width: 100%;
  height: 100%;
}
</style>